"use client";
import { useTranslations } from "next-intl";
import Image from "next/image";
import ExternalLink from "../Utility/ExternalLink";
import { askPrivacyConsent } from "@/analytics";
import { Link, pathnames } from "@/navigation";
import {
  Box,
  Flex,
  Grid,
  Link as RadixLink,
  Separator,
} from "@radix-ui/themes";
import styles from "./Footer.module.scss";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import React, { ComponentProps } from "react";
import { TextV2 } from "@/design-system/components/text/TextV2";
import logoText from "/public/logo_text.svg";
import waveHolder from "/public/footer_wave.svg";
import { InstagramLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons";

export default function Footer() {
  const t = useTranslations("Footer");

  return (
    <Flex direction={"column"} mt={"5"}>
      <Box
        className={styles.waveHolder}
        style={{ backgroundImage: `url(${waveHolder.src})` }}
      />
      <LayoutContainerV2 className={styles.footerHolder}>
        <Flex direction={"column"} gap={"5"} my={"9"}>
          <Flex direction={"column"} gap={"6"}>
            <Flex
              direction={{ initial: "column", md: "row" }}
              gap={{ initial: "7", sm: "9" }}
            >
              <Box className={styles.sectionCompanyContainer}>
                <Box className={styles.logoWrapper} mb={"5"}>
                  <Image src={logoText} alt={"Logo"} />
                </Box>
                <TextV2 textStyle={"Body M"}>{t("company_summary")}</TextV2>
              </Box>
              <Flex
                direction={{ initial: "column", sm: "row" }}
                gap={{ initial: "7", sm: "9" }}
                flexGrow={"1"}
                className={styles.companyResourcesLegalWrapper}
              >
                <FooterSection title={t("company.title")}>
                  <CompanyLinks />
                </FooterSection>
                <FooterSection title={t("resources.title")}>
                  <ResourcesLinks />
                </FooterSection>
                <FooterSection title={t("legal.title")}>
                  <LegalLinks />
                </FooterSection>
              </Flex>
            </Flex>
            <Flex direction={"row"} gap={"2"} align={"center"}>
              <ExternalLink
                url={"https://www.instagram.com/complicated___life/"}
              >
                <InstagramLogoIcon
                  color={"var(--colorV2-dark-green)"}
                  height={20}
                  width={20}
                  opacity={0.5}
                />
              </ExternalLink>
              <ExternalLink
                url={"https://linkedin.com/company/its-complicated/"}
              >
                <LinkedInLogoIcon
                  color={"var(--colorV2-dark-green)"}
                  opacity={0.5}
                  height={20}
                  width={20}
                />
              </ExternalLink>
              © 2024 It&apos;s Complicated
            </Flex>
          </Flex>
          <Separator size={"4"} />
          <Grid
            columns={{ initial: "1", sm: "2", md: "4" }}
            gap={{ initial: "7", sm: "9" }}
            justify={"between"}
          >
            <FooterSection title={t("therapists_by_city.title")}>
              <CityList />
            </FooterSection>
            <FooterSection title={t("therapists_by_language.title")}>
              <LanguageList />
            </FooterSection>
            <FooterSection title={t("therapists_by_specialization.title")}>
              <SpecializationList />
            </FooterSection>
            <FooterSection title={t("therapists_by_approach.title")}>
              <ApproachList />
            </FooterSection>
          </Grid>
        </Flex>
      </LayoutContainerV2>
      <LayoutContainerV2 className={styles.disclaimerHolder}>
        <Flex direction={"column"} align={"center"} my={"7"}>
          <TextV2 textStyle={"Body M"} align={"center"}>
            {t("disclaimer")}
          </TextV2>
          <TextV2 textStyle={"Body M"} align={"center"}>
            {t.rich("disclaimer_2", {
              resources: (chunks) => (
                <RadixLink href="/resources" className={"darkGreenLink"}>
                  {chunks}
                </RadixLink>
              ),
            })}
          </TextV2>
        </Flex>
      </LayoutContainerV2>
    </Flex>
  );
}

function CompanyLinks() {
  const t = useTranslations("Footer");

  return (
    <ul>
      <li>
        <InternalLink href="/find-a-therapist">
          {t("company.find_therapist")}
        </InternalLink>
      </li>
      <li>
        <ExternalLink url="https://product.complicated.life/for-therapists">
          <LinkText>{t("company.for_therapists")}</LinkText>
        </ExternalLink>
      </li>
      <li>
        <ExternalLink url="https://product.complicated.life/for-companies">
          <LinkText>{t("company.for_companies")}</LinkText>
        </ExternalLink>
      </li>
      <li>
        <InternalLink href="/matching/survey">
          {t("company.get_matched")}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/our-story">{t("company.our_story")}</InternalLink>
      </li>
      <li>
        <InternalLink href="/support/faq">{t("company.faq")}</InternalLink>
      </li>
    </ul>
  );
}

function ResourcesLinks() {
  const t = useTranslations("Footer");

  return (
    <ul>
      <li>
        <ExternalLink url="https://complicated.life/blog">
          <LinkText>{t("resources.blog")}</LinkText>
        </ExternalLink>
      </li>
      <li>
        <InternalLink href="/find-a-therapist/online-therapy">
          {t("resources.online_therapy")}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/self-tests/depression">
          {t("resources.depression_test")}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/self-tests/adhd">
          {t("resources.adhd_test")}
        </InternalLink>
      </li>
      <li>
        <ExternalLink url="https://open.spotify.com/show/6PsVdBM8ed4z6U7UCPSGMx">
          <LinkText>{t("resources.podcast")}</LinkText>
        </ExternalLink>
      </li>
      <li>
        <InternalLink href="/contact">
          <LinkText>{t("legal.contact")}</LinkText>
        </InternalLink>
      </li>
    </ul>
  );
}

function LegalLinks() {
  const t = useTranslations("Footer");

  return (
    <ul>
      <li>
        <InternalLink href="/imprint">{t("legal.imprint")}</InternalLink>
      </li>
      <li>
        <InternalLink href="/cookies">{t("legal.cookies")}</InternalLink>
      </li>
      <li>
        <InternalLink href="/security">{t("legal.security")}</InternalLink>
      </li>
      <li>
        <InternalLink href="/privacy">{t("legal.privacy")}</InternalLink>
      </li>
      <li>
        <InternalLink href="/terms">{t("legal.tos")}</InternalLink>
      </li>
      <li>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            askPrivacyConsent();
          }}
        >
          <LinkText>{t("legal.privacy_settings")}</LinkText>
        </a>
      </li>
    </ul>
  );
}

function CityList() {
  const t = useTranslations("Footer.therapists_by_city");

  return (
    <ul>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/[city_slug]",
            params: { city_slug: "berlin" },
          }}
        >
          {t("therapists_in", {
            city: t("berlin"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/[city_slug]",
            params: { city_slug: "amsterdam" },
          }}
        >
          {t("therapists_in", {
            city: t("amsterdam"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/[city_slug]",
            params: { city_slug: "london" },
          }}
        >
          {t("therapists_in", {
            city: t("london"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/[city_slug]",
            params: { city_slug: "hamburg" },
          }}
        >
          {t("therapists_in", {
            city: t("hamburg"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/[city_slug]",
            params: { city_slug: "lisbon" },
          }}
        >
          {t("therapists_in", {
            city: t("lisbon"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/cities">{t("see_all")}</InternalLink>
      </li>
    </ul>
  );
}

function LanguageList() {
  const t = useTranslations("Footer.therapists_by_language");

  return (
    <ul>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/speaking/[lang]",
            params: { lang: "english" },
          }}
        >
          {t("therapists_speaking", {
            language: t("english"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/speaking/[lang]",
            params: { lang: "german" },
          }}
        >
          {t("therapists_speaking", {
            language: t("german"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/speaking/[lang]",
            params: { lang: "spanish" },
          }}
        >
          {t("therapists_speaking", {
            language: t("spanish"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/speaking/[lang]",
            params: { lang: "french" },
          }}
        >
          {t("therapists_speaking", {
            language: t("french"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-a-therapist/speaking/[lang]",
            params: { lang: "russian" },
          }}
        >
          {t("therapists_speaking", {
            language: t("russian"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/languages">{t("see_all")}</InternalLink>
      </li>
    </ul>
  );
}

function SpecializationList() {
  const t = useTranslations("Footer.therapists_by_specialization");

  return (
    <ul>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "depression" },
          }}
        >
          {t("therapists_specializing_in", {
            specialization: t("depression"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "anxiety" },
          }}
        >
          {t("therapists_specializing_in", {
            specialization: t("anxiety"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "trauma" },
          }}
        >
          {t("therapists_specializing_in", {
            specialization: t("trauma"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "stress" },
          }}
        >
          {t("therapists_specializing_in", {
            specialization: t("stress"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: {
              category_slug: "attention-deficit-hyperactivity-disorder-adhd",
            },
          }}
        >
          {t("therapists_specializing_in", {
            specialization: t("adhd"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/specializations">{t("see_all")}</InternalLink>
      </li>
    </ul>
  );
}

function ApproachList() {
  const t = useTranslations("Footer.therapists_by_approach");

  return (
    <ul>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "cbt-cognitive-behavioral-therapy" },
          }}
        >
          {t("therapists_practicing", {
            approach: t("cbt"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "coaching" },
          }}
        >
          {t("therapists_practicing", {
            approach: t("coaching"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "couples-therapy" },
          }}
        >
          {t("therapists_practicing", {
            approach: t("couples_therapy"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "psychoanalysis" },
          }}
        >
          {t("therapists_practicing", {
            approach: t("psychoanalysis"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink
          href={{
            pathname: "/find-help-with/[category_slug]",
            params: { category_slug: "psychedelic-integration-therapy" },
          }}
        >
          {t("therapists_practicing", {
            approach: t("psychadelic_integration"),
          })}
        </InternalLink>
      </li>
      <li>
        <InternalLink href="/approaches">{t("see_all")}</InternalLink>
      </li>
    </ul>
  );
}

function InternalLink<Pathname extends keyof typeof pathnames>({
  href,
  children,
}: {
  href: ComponentProps<typeof Link<Pathname>>["href"];
  children: React.ReactNode;
}) {
  return (
    <Link href={href}>
      <LinkText>{children}</LinkText>
    </Link>
  );
}

function FooterSection({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Box className={styles.sectionLinksContainer}>
      <HeadingV2
        textStyle={"Headline S"}
        mb={{ initial: "3", md: "4" }}
        as={"h3"}
      >
        {title}
      </HeadingV2>
      {children}
    </Box>
  );
}

function LinkText({ children }: { children: React.ReactNode }) {
  return <TextV2 textStyle={"Body M"}>{children}</TextV2>;
}
